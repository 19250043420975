var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.MutationTypes = void 0;

  /** @module @airtable/blocks: mutations */

  /** */

  /** @hidden */
  var MutationTypes = Object.freeze({
    SET_MULTIPLE_RECORDS_CELL_VALUES: "setMultipleRecordsCellValues",
    DELETE_MULTIPLE_RECORDS: "deleteMultipleRecords",
    CREATE_MULTIPLE_RECORDS: "createMultipleRecords",
    SET_MULTIPLE_GLOBAL_CONFIG_PATHS: "setMultipleGlobalConfigPaths",
    CREATE_SINGLE_FIELD: "createSingleField",
    UPDATE_SINGLE_FIELD_CONFIG: "updateSingleFieldConfig",
    UPDATE_SINGLE_FIELD_DESCRIPTION: "updateSingleFieldDescription",
    UPDATE_SINGLE_FIELD_NAME: "updateSingleFieldName",
    CREATE_SINGLE_TABLE: "createSingleTable",
    UPDATE_VIEW_METADATA: "updateViewMetadata"
  });
  /** @hidden */

  exports.MutationTypes = MutationTypes;
  return exports;
}