import * as _esString2 from "core-js/modules/es.string.replace";
var _esString = _esString2;
try {
  if ("default" in _esString2) _esString = _esString2.default;
} catch (e) {}
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  _esString;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.spawnError = spawnError;
  exports.logErrorToSentry = logErrorToSentry;
  exports.invariant = invariant;
  exports.spawnUnknownSwitchCaseError = spawnUnknownSwitchCaseError;
  exports.spawnAbstractMethodError = spawnAbstractMethodError;

  // If errorOriginFn is specified, all frames above and including the call to errorOriginFn
  // will be omitted from the stack trace.

  /**
   * @internal
   */
  function spawnErrorWithOriginOmittedFromStackTrace(errorMessageFormat, errorMessageArgs, errorOriginFn) {
    var safeMessage = errorMessageFormat;
    var argIndex = 0;
    var formattedMessage = errorMessageFormat.replace(/%s/g, () => {
      var arg = errorMessageArgs ? errorMessageArgs[argIndex] : undefined;
      argIndex++;
      return String(arg);
    });
    var err = new Error(formattedMessage); // captureStackTrace is only available on v8. It captures the current stack trace
    // and sets the .stack property of the first argument. It will omit all frames above
    // and including "errorOriginFn", which is useful for hiding implementation details of our
    // error throwing helpers (e.g. assert and spawn variants).

    if (Error.captureStackTrace && errorOriginFn) {
      Error.captureStackTrace(err, errorOriginFn);
    }
    Object.defineProperty(err, "__safeMessage", {
      configurable: false,
      enumerable: false,
      value: safeMessage,
      writable: false
    });
    return err;
  }
  /**
   * @hidden
   */

  function spawnError(errorMessageFormat) {
    for (var _len = arguments.length, errorMessageArgs = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      errorMessageArgs[_key - 1] = arguments[_key];
    }
    return spawnErrorWithOriginOmittedFromStackTrace(errorMessageFormat, errorMessageArgs, spawnError);
  } // istanbul ignore next

  /**
   * Logs an error to Sentry
   *
   * @hidden
   */

  function logErrorToSentry(errorMessage, metadata) {
    // See this comment for how to log via Sentry: https://github.com/Hyperbase/hyperbase/blob/2c5438ecaddcb9fa196e027e40df5e0c68c90989/client/run_block_frame.tsx#L333
    var blocksErrorReporter = window.blocksErrorReporter;
    if (blocksErrorReporter === null || blocksErrorReporter === void 0 ? void 0 : blocksErrorReporter.reportWarning) {
      blocksErrorReporter.reportWarning(errorMessage, metadata);
    }
  }
  /**
   * An alternative to facebook's invariant that's safe to use with base data
   *
   * @hidden
   */

  function invariant(condition, errorMessageFormat) {
    if (!condition) {
      for (var _len2 = arguments.length, errorMessageArgs = new Array(_len2 > 2 ? _len2 - 2 : 0), _key2 = 2; _key2 < _len2; _key2++) {
        errorMessageArgs[_key2 - 2] = arguments[_key2];
      }
      throw spawnErrorWithOriginOmittedFromStackTrace(errorMessageFormat, errorMessageArgs, invariant);
    }
  }
  /**
   * @internal
   */

  function spawnUnknownSwitchCaseError(valueDescription, providedValue, key) {
    var providedValueKey = providedValue[key];
    var providedValueKeyString = providedValueKey !== null && providedValueKey !== undefined ? providedValueKey : "null";
    return spawnErrorWithOriginOmittedFromStackTrace("Unknown value %s for %s", [providedValueKeyString, valueDescription], spawnUnknownSwitchCaseError);
  }
  /**
   * @internal
   */

  function spawnAbstractMethodError() {
    return spawnErrorWithOriginOmittedFromStackTrace("Abstract method", undefined, spawnAbstractMethodError);
  }
  return exports;
}