var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.FieldType = void 0;

  /** @module @airtable/blocks/models: Field */

  /** */

  /** */

  /** @hidden */

  /**
   * An enum of Airtable's field types
   *
   * @example
   * ```js
   * import {FieldType} from '@airtable/blocks/models';
   * const numberFields = myTable.fields.filter(field => (
   *     field.type === FieldType.NUMBER
   * ));
   * ```
   */
  var FieldType;
  /** @hidden */

  exports.FieldType = FieldType;
  (function (FieldType) {
    FieldType["SINGLE_LINE_TEXT"] = "singleLineText";
    FieldType["EMAIL"] = "email";
    FieldType["URL"] = "url";
    FieldType["MULTILINE_TEXT"] = "multilineText";
    FieldType["NUMBER"] = "number";
    FieldType["PERCENT"] = "percent";
    FieldType["CURRENCY"] = "currency";
    FieldType["SINGLE_SELECT"] = "singleSelect";
    FieldType["MULTIPLE_SELECTS"] = "multipleSelects";
    FieldType["SINGLE_COLLABORATOR"] = "singleCollaborator";
    FieldType["MULTIPLE_COLLABORATORS"] = "multipleCollaborators";
    FieldType["MULTIPLE_RECORD_LINKS"] = "multipleRecordLinks";
    FieldType["DATE"] = "date";
    FieldType["DATE_TIME"] = "dateTime";
    FieldType["PHONE_NUMBER"] = "phoneNumber";
    FieldType["MULTIPLE_ATTACHMENTS"] = "multipleAttachments";
    FieldType["CHECKBOX"] = "checkbox";
    FieldType["FORMULA"] = "formula";
    FieldType["CREATED_TIME"] = "createdTime";
    FieldType["ROLLUP"] = "rollup";
    FieldType["COUNT"] = "count";
    FieldType["MULTIPLE_LOOKUP_VALUES"] = "multipleLookupValues";
    FieldType["AUTO_NUMBER"] = "autoNumber";
    FieldType["BARCODE"] = "barcode";
    FieldType["RATING"] = "rating";
    FieldType["RICH_TEXT"] = "richText";
    FieldType["DURATION"] = "duration";
    FieldType["LAST_MODIFIED_TIME"] = "lastModifiedTime";
    FieldType["CREATED_BY"] = "createdBy";
    FieldType["LAST_MODIFIED_BY"] = "lastModifiedBy";
    FieldType["BUTTON"] = "button";
    FieldType["EXTERNAL_SYNC_SOURCE"] = "externalSyncSource";
    FieldType["AI_TEXT"] = "aiText";
  })(FieldType || (exports.FieldType = FieldType = {}));
  return exports;
}