import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import * as _propTypes3 from "prop-types";
var _propTypes2 = _propTypes3;
try {
  if ("default" in _propTypes3) _propTypes2 = _propTypes3.default;
} catch (e) {}
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireDefault = _interopRequireDefault2;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = createResponsivePropType;
  var _propTypes = _interopRequireDefault(_propTypes2);

  /** @internal */
  function createResponsivePropType(propType) {
    return _propTypes.default.oneOfType([propType,
    // Responsive values map to `ResponsivePropObject`
    _propTypes.default.shape({
      xsmallViewport: propType,
      smallViewport: propType,
      mediumViewport: propType,
      largeViewport: propType
    })]);
  }
  return exports;
}