import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";
var _interopRequireWildcard2 = _interopRequireWildcard3;
try {
  if ("default" in _interopRequireWildcard3) _interopRequireWildcard2 = _interopRequireWildcard3.default;
} catch (e) {}
import * as _esObject2 from "core-js/modules/es.object.to-string";
var _esObject = _esObject2;
try {
  if ("default" in _esObject2) _esObject = _esObject2.default;
} catch (e) {}
import * as _esRegexp2 from "core-js/modules/es.regexp.to-string";
var _esRegexp = _esRegexp2;
try {
  if ("default" in _esRegexp2) _esRegexp = _esRegexp2.default;
} catch (e) {}
import * as _esString2 from "core-js/modules/es.string.ends-with";
var _esString = _esString2;
try {
  if ("default" in _esString2) _esString = _esString2.default;
} catch (e) {}
import * as _esString4 from "core-js/modules/es.string.pad-start";
var _esString3 = _esString4;
try {
  if ("default" in _esString4) _esString3 = _esString4.default;
} catch (e) {}
import { dew as _private_utils2Dew } from "./private_utils";
import { dew as _colors2Dew } from "./colors";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireWildcard = _interopRequireWildcard2;
  _esObject;
  _esRegexp;
  _esString;
  _esString3;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = void 0;
  var _private_utils = _private_utils2Dew();
  var _colors = _interopRequireWildcard(_colors2Dew());

  /** @module @airtable/blocks/ui: colorUtils */

  /** */
  var colorUtils = {
    getHexForColor: colorString => {
      var color = (0, _private_utils.getEnumValueIfExists)(_colors.default, colorString);
      if (!color) {
        return null;
      }
      var rgbTuple = _colors.rgbTuplesByColor[color];
      var hexNumber = rgbTuple[0] << 16 | rgbTuple[1] << 8 | rgbTuple[2];
      return "#".concat(hexNumber.toString(16).padStart(6, "0"));
    },
    getRgbForColor: colorString => {
      var color = (0, _private_utils.getEnumValueIfExists)(_colors.default, colorString);
      if (!color) {
        return null;
      }
      var rgbTuple = _colors.rgbTuplesByColor[color];
      return {
        r: rgbTuple[0],
        g: rgbTuple[1],
        b: rgbTuple[2]
      };
    },
    shouldUseLightTextOnColor: colorString => {
      if (!(0, _private_utils.has)(_colors.rgbTuplesByColor, colorString)) {
        // Don't have a color for this. Let's just return false as a default
        // instead of throwing.
        return false;
      } // Light1 and Light2 colors use dark text.
      // Bright, Dark1 and no suffix colors use light text.
      // NOTE: use shouldUseDarkText instead of shouldUseLightText just to make
      // checking the suffix easier, since no suffix uses light text.

      var shouldUseDarkText = colorString.endsWith("Light1") || colorString.endsWith("Light2");
      return !shouldUseDarkText;
    }
  };
  var _default = colorUtils;
  exports.default = _default;
  return exports;
}