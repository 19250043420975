import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";
var _interopRequireWildcard2 = _interopRequireWildcard3;
try {
  if ("default" in _interopRequireWildcard3) _interopRequireWildcard2 = _interopRequireWildcard3.default;
} catch (e) {}
import { dew as _record_coloringDew } from "./record_coloring";
import { dew as _create_aggregators2Dew } from "./create_aggregators";
import { dew as _field3Dew } from "../types/field";
import { dew as _view3Dew } from "../types/view";
import { dew as _base2Dew } from "./base";
import { dew as _table2Dew } from "./table";
import { dew as _field4Dew } from "./field";
import { dew as _view4Dew } from "./view";
import { dew as _record2Dew } from "./record";
import { dew as _record_query_result2Dew } from "./record_query_result";
import { dew as _table_or_view_query_result2Dew } from "./table_or_view_query_result";
import { dew as _grouped_record_query_result2Dew } from "./grouped_record_query_result";
import { dew as _linked_records_query_result2Dew } from "./linked_records_query_result";
import { dew as _view_metadata_query_result2Dew } from "./view_metadata_query_result";
import { dew as _session2Dew } from "./session";
import { dew as _cursor2Dew } from "./cursor";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireDefault = _interopRequireDefault2;
  var _interopRequireWildcard = _interopRequireWildcard2;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "FieldType", {
    enumerable: true,
    get: function get() {
      return _field.FieldType;
    }
  });
  Object.defineProperty(exports, "FieldConfig", {
    enumerable: true,
    get: function get() {
      return _field.FieldConfig;
    }
  });
  Object.defineProperty(exports, "ViewType", {
    enumerable: true,
    get: function get() {
      return _view.ViewType;
    }
  });
  Object.defineProperty(exports, "Base", {
    enumerable: true,
    get: function get() {
      return _base.default;
    }
  });
  Object.defineProperty(exports, "Table", {
    enumerable: true,
    get: function get() {
      return _table.default;
    }
  });
  Object.defineProperty(exports, "Field", {
    enumerable: true,
    get: function get() {
      return _field2.default;
    }
  });
  Object.defineProperty(exports, "View", {
    enumerable: true,
    get: function get() {
      return _view2.default;
    }
  });
  Object.defineProperty(exports, "Record", {
    enumerable: true,
    get: function get() {
      return _record.default;
    }
  });
  Object.defineProperty(exports, "RecordQueryResult", {
    enumerable: true,
    get: function get() {
      return _record_query_result.default;
    }
  });
  Object.defineProperty(exports, "TableOrViewQueryResult", {
    enumerable: true,
    get: function get() {
      return _table_or_view_query_result.default;
    }
  });
  Object.defineProperty(exports, "GroupedRecordQueryResult", {
    enumerable: true,
    get: function get() {
      return _grouped_record_query_result.default;
    }
  });
  Object.defineProperty(exports, "LinkedRecordsQueryResult", {
    enumerable: true,
    get: function get() {
      return _linked_records_query_result.default;
    }
  });
  Object.defineProperty(exports, "ViewMetadataQueryResult", {
    enumerable: true,
    get: function get() {
      return _view_metadata_query_result.default;
    }
  });
  Object.defineProperty(exports, "Session", {
    enumerable: true,
    get: function get() {
      return _session.default;
    }
  });
  Object.defineProperty(exports, "Cursor", {
    enumerable: true,
    get: function get() {
      return _cursor.default;
    }
  });
  exports.recordColoring = void 0;
  var recordColoring = _interopRequireWildcard(_record_coloringDew());
  exports.recordColoring = recordColoring;
  var _create_aggregators = _interopRequireDefault(_create_aggregators2Dew());
  var _field = _field3Dew();
  var _view = _view3Dew();
  var _base = _interopRequireDefault(_base2Dew());
  var _table = _interopRequireDefault(_table2Dew());
  var _field2 = _interopRequireDefault(_field4Dew());
  var _view2 = _interopRequireDefault(_view4Dew());
  var _record = _interopRequireDefault(_record2Dew());
  var _record_query_result = _interopRequireDefault(_record_query_result2Dew());
  var _table_or_view_query_result = _interopRequireDefault(_table_or_view_query_result2Dew());
  var _grouped_record_query_result = _interopRequireDefault(_grouped_record_query_result2Dew());
  var _linked_records_query_result = _interopRequireDefault(_linked_records_query_result2Dew());
  var _view_metadata_query_result = _interopRequireDefault(_view_metadata_query_result2Dew());
  var _session = _interopRequireDefault(_session2Dew());
  var _cursor = _interopRequireDefault(_cursor2Dew());

  // istanbul ignore file

  /** @ignore */

  /** */
  var initializedAggregators = null;
  Object.defineProperty(exports, "aggregators", {
    enumerable: true,
    get: () => {
      if (!initializedAggregators) {
        initializedAggregators = (0, _create_aggregators.default)();
      }
      return initializedAggregators;
    }
  });
  return exports;
}