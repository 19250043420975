import { dew as _error_utils2Dew } from "../error_utils";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = getAirtableInterface;
  var _error_utils = _error_utils2Dew();
  var AIRTABLE_INTERFACE_VERSION = 0;
  var airtableInterface = null;
  var missingAirtableInterfaceErrorMessage = ["Error: Extension environment misconfigured", "\n\n", "Airtable Extensions can only run in the presence of an Airtable Base. If you ", "are trying to run your Extension with a Base hosted on airtable.com, then be ", "sure you are using the Airtable CLI to serve your code and accessing it ", "through a Custom Extension installed inside a Base on airtable.com.", "\n\n", "If you are trying to run automated tests for your Extension, then make sure ", "you have loaded the `@airtable/testing-library` module *before* the ", "`@airtable/blocks` module."].join("");
  /** @hidden */

  function getAirtableInterface() {
    var getAirtableInterfaceAtVersion = window.__getAirtableInterfaceAtVersion;
    if (!airtableInterface) {
      if (!getAirtableInterfaceAtVersion) {
        throw (0, _error_utils.spawnError)(missingAirtableInterfaceErrorMessage);
      }
      airtableInterface = getAirtableInterfaceAtVersion(AIRTABLE_INTERFACE_VERSION);
    }
    return airtableInterface;
  }
  return exports;
}