import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import * as _es2 from "core-js/modules/es.symbol";
var _es = _es2;
try {
  if ("default" in _es2) _es = _es2.default;
} catch (e) {}
import * as _esSymbol2 from "core-js/modules/es.symbol.description";
var _esSymbol = _esSymbol2;
try {
  if ("default" in _esSymbol2) _esSymbol = _esSymbol2.default;
} catch (e) {}
import * as _esArray2 from "core-js/modules/es.array.concat";
var _esArray = _esArray2;
try {
  if ("default" in _esArray2) _esArray = _esArray2.default;
} catch (e) {}
import * as _esArray4 from "core-js/modules/es.array.filter";
var _esArray3 = _esArray4;
try {
  if ("default" in _esArray4) _esArray3 = _esArray4.default;
} catch (e) {}
import * as _esArray6 from "core-js/modules/es.array.flat";
var _esArray5 = _esArray6;
try {
  if ("default" in _esArray6) _esArray5 = _esArray6.default;
} catch (e) {}
import * as _esArray8 from "core-js/modules/es.array.iterator";
var _esArray7 = _esArray8;
try {
  if ("default" in _esArray8) _esArray7 = _esArray8.default;
} catch (e) {}
import * as _esArrayUnscopables2 from "core-js/modules/es.array.unscopables.flat";
var _esArrayUnscopables = _esArrayUnscopables2;
try {
  if ("default" in _esArrayUnscopables2) _esArrayUnscopables = _esArrayUnscopables2.default;
} catch (e) {}
import * as _esObject2 from "core-js/modules/es.object.entries";
var _esObject = _esObject2;
try {
  if ("default" in _esObject2) _esObject = _esObject2.default;
} catch (e) {}
import * as _esObject4 from "core-js/modules/es.object.to-string";
var _esObject3 = _esObject4;
try {
  if ("default" in _esObject4) _esObject3 = _esObject4.default;
} catch (e) {}
import * as _esObject6 from "core-js/modules/es.object.values";
var _esObject5 = _esObject6;
try {
  if ("default" in _esObject6) _esObject5 = _esObject6.default;
} catch (e) {}
import * as _es4 from "core-js/modules/es.set";
var _es3 = _es4;
try {
  if ("default" in _es4) _es3 = _es4.default;
} catch (e) {}
import * as _es6 from "core-js/modules/es.weak-map";
var _es5 = _es6;
try {
  if ("default" in _es6) _es5 = _es6.default;
} catch (e) {}
import * as _webDomCollections2 from "core-js/modules/web.dom-collections.iterator";
var _webDomCollections = _webDomCollections2;
try {
  if ("default" in _webDomCollections2) _webDomCollections = _webDomCollections2.default;
} catch (e) {}
import * as _propTypes3 from "prop-types";
var _propTypes2 = _propTypes3;
try {
  if ("default" in _propTypes3) _propTypes2 = _propTypes3.default;
} catch (e) {}
import { dew as _airtable_interface2Dew } from "./injected/airtable_interface";
import { dew as _error_utils2Dew } from "./error_utils";
import { dew as _create_responsive_prop_type2Dew } from "./ui/system/utils/create_responsive_prop_type";
import * as _fastDeepEqual3 from "fast-deep-equal";
var _fastDeepEqual2 = _fastDeepEqual3;
try {
  if ("default" in _fastDeepEqual3) _fastDeepEqual2 = _fastDeepEqual3.default;
} catch (e) {}
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireDefault = _interopRequireDefault2;
  _es;
  _esSymbol;
  _esArray;
  _esArray3;
  _esArray5;
  _esArray7;
  _esArrayUnscopables;
  _esObject;
  _esObject3;
  _esObject5;
  _es3;
  _es5;
  _webDomCollections;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.createEnum = createEnum;
  exports.createPropTypeFromEnum = createPropTypeFromEnum;
  exports.createResponsivePropTypeFromEnum = createResponsivePropTypeFromEnum;
  exports.cast = cast;
  exports.cloneDeep = cloneDeep;
  exports.keys = keys;
  exports.values = values;
  exports.entries = entries;
  exports.fireAndForgetPromise = fireAndForgetPromise;
  exports.has = has;
  exports.getEnumValueIfExists = getEnumValueIfExists;
  exports.assertEnumValue = assertEnumValue;
  exports.isEnumValue = isEnumValue;
  exports.isObjectEmpty = isObjectEmpty;
  exports.isNullOrUndefinedOrEmpty = isNullOrUndefinedOrEmpty;
  exports.compact = compact;
  exports.clamp = clamp;
  exports.flattenDeep = flattenDeep;
  exports.keyBy = keyBy;
  exports.getId = getId;
  exports.uniqBy = uniqBy;
  exports.getLocallyUniqueId = getLocallyUniqueId;
  exports.getValueAtOwnPath = getValueAtOwnPath;
  exports.arrayDifference = arrayDifference;
  exports.debounce = debounce;
  exports.isBlockDevelopmentRestrictionEnabled = isBlockDevelopmentRestrictionEnabled;
  exports.getLocaleAndDefaultLocale = getLocaleAndDefaultLocale;
  Object.defineProperty(exports, "isDeepEqual", {
    enumerable: true,
    get: function get() {
      return _fastDeepEqual.default;
    }
  });
  var _propTypes = _interopRequireDefault(_propTypes2);
  var _airtable_interface = _interopRequireDefault(_airtable_interface2Dew());
  var _error_utils = _error_utils2Dew();
  var _create_responsive_prop_type = _interopRequireDefault(_create_responsive_prop_type2Dew());
  var _fastDeepEqual = _interopRequireDefault(_fastDeepEqual2);

  /**
   * Creates an enum from provided string arguments.
   *
   * Useful for consumer-facing enums (eg `Button#variant`) where we want to make the external
   * developer experience convenient by providing a string value, but also want to internally
   * reference enum values using object notation.
   *
   * @hidden
   */
  function createEnum() {
    var spec = {};
    for (var _len = arguments.length, enumValues = new Array(_len), _key = 0; _key < _len; _key++) {
      enumValues[_key] = arguments[_key];
    }
    for (var _i = 0, _enumValues = enumValues; _i < _enumValues.length; _i++) {
      var value = _enumValues[_i];
      spec[value] = value;
    }
    return Object.freeze(spec);
  }
  /**
   * Creates a React propType for a provided enum.
   *
   * @hidden
   */

  function createPropTypeFromEnum(enumData) {
    return _propTypes.default.oneOf(values(enumData));
  }
  /**
   * Creates a responsive React propType for a provided enum.
   *
   * This allows the prop to be either a valid enum property, or a map of viewport sizes to valid enum
   * properties.
   *
   * @hidden
   */

  function createResponsivePropTypeFromEnum(enumData) {
    var propType = createPropTypeFromEnum(enumData);
    return (0, _create_responsive_prop_type.default)(propType);
  }
  /**
   * Creates a Type for an enum created using `createEnum`.
   *
   * @hidden
   */

  /**
   * Safely cast a value to the type passed in as a type parameter.
   *
   * This was added in the TypeScript migration to provide a safe translation for Flow’s type cast
   * operator `(x: T)`.
   *
   * @hidden
   */
  function cast(x) {
    return x;
  }
  /**
   * @hidden
   */

  function cloneDeep(obj) {
    var jsonString = JSON.stringify(obj);
    if (jsonString === undefined) {
      return obj;
    }
    return JSON.parse(jsonString);
  } // typescript has a stricter definition for Object.values and Object.entries that return mixed in place
  // of the actual values. This is because for non-exact objects, that's the only sound definition.
  // You can call Object.values with a value typed as {x: number} that actually looks like
  // {x: number, y: string}, for example. Returning mixed isn't particularly useful though, so we
  // provide these unsound wrappers instead.
  // TODO: consider renaming these with unsound_ prefixes.

  /**
   * @hidden
   */

  function keys(obj) {
    return Object.keys(obj);
  }
  /**
   * @hidden
   */

  function values(obj) {
    return Object.values(obj);
  }
  /**
   * @hidden
   */

  function entries(obj) {
    // @ts-ignore
    return Object.entries(obj);
  } // Result values are discarded and errors are thrown asynchronously.
  // NOTE: this is different from the one in u: the function passed
  // in must be fully bound with all of its arguments and will be immediately
  // called (this does not return a function). This makes it work better with
  // Flow: you get argument type checking by using `.bind`.

  /**
   * @hidden
   */

  function fireAndForgetPromise(fn) {
    fn().catch(err => {
      // Defer til later, so the error doesn't cause the promise to be rejected.
      setTimeout(() => {
        throw err;
      }, 0);
    });
  }
  /**
   * @hidden
   */

  function has(obj, key) {
    return Object.prototype.hasOwnProperty.call(obj, key);
  }
  var invertedEnumCache = new WeakMap();
  /**
   * @hidden
   */

  function getInvertedEnumMemoized(enumObj) {
    var existingInvertedEnum = invertedEnumCache.get(enumObj);
    if (existingInvertedEnum) {
      // typescript can't type WeakMap precisely enough to know that it's being used as this sort of cache
      return existingInvertedEnum;
    }
    var invertedEnum = {};
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;
    try {
      for (var _iterator = keys(enumObj)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var enumKey = _step.value;
        var enumValue = enumObj[enumKey];
        invertedEnum[enumValue] = enumKey;
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return != null) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }
    invertedEnumCache.set(enumObj, invertedEnum);
    return invertedEnum;
  }
  /**
   * @hidden
   */

  function getEnumValueIfExists(enumObj, valueToCheck) {
    var invertedEnum = getInvertedEnumMemoized(enumObj);
    if (has(invertedEnum, valueToCheck) && invertedEnum[valueToCheck]) {
      var enumKey = invertedEnum[valueToCheck];
      return enumObj[enumKey];
    }
    return null;
  }
  /**
   * @hidden
   */

  function assertEnumValue(enumObj, valueToCheck) {
    var enumValue = getEnumValueIfExists(enumObj, valueToCheck);
    if (!enumValue) {
      throw (0, _error_utils.spawnError)("Unknown enum value %s", valueToCheck);
    }
    return enumValue;
  }
  /**
   * @hidden
   */

  function isEnumValue(enumObj, valueToCheck) {
    return getEnumValueIfExists(enumObj, valueToCheck) !== null;
  }
  /**
   * @hidden
   */

  function isObjectEmpty(obj) {
    for (var _key2 in obj) {
      if (has(obj, _key2)) {
        return false;
      }
    }
    return true;
  }
  /**
   * @hidden
   */

  function isNullOrUndefinedOrEmpty(value) {
    return value === null || value === undefined || (typeof value === "string" || Array.isArray(value)) && value.length === 0 || typeof value === "object" && value !== null && isObjectEmpty(value);
  }
  /**
   * @hidden
   */

  function compact(array) {
    var result = [];
    var _iteratorNormalCompletion2 = true;
    var _didIteratorError2 = false;
    var _iteratorError2 = undefined;
    try {
      for (var _iterator2 = array[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
        var item = _step2.value;
        if (item !== null && item !== undefined) {
          result.push(item);
        }
      }
    } catch (err) {
      _didIteratorError2 = true;
      _iteratorError2 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
          _iterator2.return();
        }
      } finally {
        if (_didIteratorError2) {
          throw _iteratorError2;
        }
      }
    }
    return result;
  }
  /**
   * @hidden
   */

  function clamp(n, lowerBound, upperBound) {
    if (n < lowerBound) {
      return lowerBound;
    } else if (n > upperBound) {
      return upperBound;
    } else {
      return n;
    }
  }
  /** @hidden */

  /**
   * @hidden
   */
  function flattenDeep(array) {
    // $FlowFixMe our version of flow doesn't include a definition for Array.flat yet
    return array.flat(Infinity);
  } // For cases where the object keys aren't strings, it's the consumer's responsibility
  // to convert them to strings in getKey, e.g. keyBy(collection, o => String(o.id))

  /**
   * @hidden
   */

  function keyBy(array, getKey) {
    var result = {};
    var _iteratorNormalCompletion3 = true;
    var _didIteratorError3 = false;
    var _iteratorError3 = undefined;
    try {
      for (var _iterator3 = array[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
        var item = _step3.value;
        result[getKey(item)] = item;
      }
    } catch (err) {
      _didIteratorError3 = true;
      _iteratorError3 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion3 && _iterator3.return != null) {
          _iterator3.return();
        }
      } finally {
        if (_didIteratorError3) {
          throw _iteratorError3;
        }
      }
    }
    return result;
  }
  /**
   * @hidden
   */

  function getId(_ref) {
    var id = _ref.id;
    return id;
  }
  /**
   * @hidden
   */

  function uniqBy(array, getKey) {
    var usedKeys = new Set();
    var result = [];
    var _iteratorNormalCompletion4 = true;
    var _didIteratorError4 = false;
    var _iteratorError4 = undefined;
    try {
      for (var _iterator4 = array[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
        var item = _step4.value;
        var _key3 = getKey(item);
        if (!usedKeys.has(_key3)) {
          result.push(item);
          usedKeys.add(_key3);
        }
      }
    } catch (err) {
      _didIteratorError4 = true;
      _iteratorError4 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion4 && _iterator4.return != null) {
          _iterator4.return();
        }
      } finally {
        if (_didIteratorError4) {
          throw _iteratorError4;
        }
      }
    }
    return result;
  }
  var idCount = 0;
  /** @hidden */

  function getLocallyUniqueId() {
    var prefix = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
    return "".concat(prefix, ".").concat(idCount++);
  }
  var plainObjectPrototype = Object.getPrototypeOf({});
  /**
   * A more restrictive version of Lodash's `get`. Notable differences:
   * - Will only search an object's own properties
   * - Only allows indexing into plain objects - searching in `number`, `string`, `Array`, `null`, or non-plain objects will throw
   *
   * @hidden
   */

  function getValueAtOwnPath(value, path) {
    var currentValue = value;
    var _iteratorNormalCompletion5 = true;
    var _didIteratorError5 = false;
    var _iteratorError5 = undefined;
    try {
      for (var _iterator5 = path[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
        var part = _step5.value;
        if (currentValue === undefined) {
          return undefined;
        }
        if (typeof currentValue !== "object" || currentValue === null) {
          throw (0, _error_utils.spawnError)("Cannot get '%s' in primitive value", part);
        }
        if (Array.isArray(currentValue)) {
          throw (0, _error_utils.spawnError)("Cannot get '%s' in array", part);
        }
        var prototype = Object.getPrototypeOf(currentValue);
        if (prototype !== null && prototype !== plainObjectPrototype) {
          throw (0, _error_utils.spawnError)("Cannot get '%s' in non-plain object", part);
        }
        currentValue = has(currentValue, part) ? currentValue[part] : undefined;
      }
    } catch (err) {
      _didIteratorError5 = true;
      _iteratorError5 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion5 && _iterator5.return != null) {
          _iterator5.return();
        }
      } finally {
        if (_didIteratorError5) {
          throw _iteratorError5;
        }
      }
    }
    return currentValue;
  }
  /** @hidden */

  function arrayDifference(a, b) {
    var bSet = new Set(b);
    return a.filter(item => !bSet.has(item));
  }
  /** @hidden */

  function debounce(fn, timeoutMs) {
    var lastTimeoutId = null;
    return function () {
      for (var _len2 = arguments.length, args = new Array(_len2), _key4 = 0; _key4 < _len2; _key4++) {
        args[_key4] = arguments[_key4];
      }
      if (lastTimeoutId !== null) {
        clearTimeout(lastTimeoutId);
      }
      lastTimeoutId = setTimeout(() => {
        lastTimeoutId = null;
        fn(...args);
      }, timeoutMs);
    };
  }
  /** @hidden */

  function isBlockDevelopmentRestrictionEnabled() {
    return (0, _airtable_interface.default)().sdkInitData.baseData.isBlockDevelopmentRestrictionEnabled;
  }
  /**
   * Added for use in Gantt View, to enable i18n.
   *
   * @hidden
   * */

  function getLocaleAndDefaultLocale() {
    var sdkInitData = (0, _airtable_interface.default)().sdkInitData;
    return {
      locale: sdkInitData.locale,
      defaultLocale: sdkInitData.defaultLocale
    };
  }
  return exports;
}