var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ViewType = void 0;

  /** @module @airtable/blocks/models: View */

  /** */

  /** */

  /**
   * An enum of Airtable's view types
   *
   * @example
   * ```js
   * import {ViewType} from '@airtable/blocks/models';
   * const gridViews = myTable.views.filter(view => (
   *     view.type === ViewType.GRID
   * ));
   * ```
   */
  var ViewType;
  /** @hidden */

  exports.ViewType = ViewType;
  (function (ViewType) {
    ViewType["GRID"] = "grid";
    ViewType["FORM"] = "form";
    ViewType["CALENDAR"] = "calendar";
    ViewType["GALLERY"] = "gallery";
    ViewType["KANBAN"] = "kanban";
    ViewType["BLOCK"] = "block";
  })(ViewType || (exports.ViewType = ViewType = {}));
  return exports;
}