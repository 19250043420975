import * as _es2 from "core-js/modules/es.symbol";
var _es = _es2;
try {
  if ("default" in _es2) _es = _es2.default;
} catch (e) {}
import * as _esSymbol2 from "core-js/modules/es.symbol.description";
var _esSymbol = _esSymbol2;
try {
  if ("default" in _esSymbol2) _esSymbol = _esSymbol2.default;
} catch (e) {}
import * as _esArray2 from "core-js/modules/es.array.iterator";
var _esArray = _esArray2;
try {
  if ("default" in _esArray2) _esArray = _esArray2.default;
} catch (e) {}
import * as _esArray4 from "core-js/modules/es.array.map";
var _esArray3 = _esArray4;
try {
  if ("default" in _esArray4) _esArray3 = _esArray4.default;
} catch (e) {}
import * as _esObject2 from "core-js/modules/es.object.to-string";
var _esObject = _esObject2;
try {
  if ("default" in _esObject2) _esObject = _esObject2.default;
} catch (e) {}
import * as _webDomCollections2 from "core-js/modules/web.dom-collections.iterator";
var _webDomCollections = _webDomCollections2;
try {
  if ("default" in _webDomCollections2) _webDomCollections = _webDomCollections2.default;
} catch (e) {}
import { dew as _error_utils2Dew } from "../error_utils";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  _es;
  _esSymbol;
  _esArray;
  _esArray3;
  _esObject;
  _webDomCollections;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = createAggregators;
  exports.__injectSdkIntoCreateAggregators = __injectSdkIntoCreateAggregators;
  var _error_utils = _error_utils2Dew();

  // istanbul ignore file

  /** @module @airtable/blocks/models: Aggregators */

  /** */
  var aggregate = (aggregatorKey, records, field) => {
    if (!field.isAggregatorAvailable(aggregatorKey)) {
      throw (0, _error_utils.spawnError)("The %s aggregator is not available for %s fields", aggregatorKey, field.type);
    }
    var _sdk2 = sdk,
      appInterface = _sdk2.__appInterface,
      airtableInterface = _sdk2.__airtableInterface;
    var cellValues = records.map(record => record._getRawCellValue(field));
    return airtableInterface.aggregators.aggregate(appInterface, aggregatorKey, cellValues, field._data);
  };
  var aggregateToString = (aggregatorKey, records, field) => {
    if (!field.isAggregatorAvailable(aggregatorKey)) {
      throw (0, _error_utils.spawnError)("The %s aggregator is not available for %s fields", aggregatorKey, field.type);
    }
    var _sdk3 = sdk,
      appInterface = _sdk3.__appInterface,
      airtableInterface = _sdk3.__airtableInterface;
    var cellValues = records.map(record => record._getRawCellValue(field));
    return airtableInterface.aggregators.aggregateToString(appInterface, aggregatorKey, cellValues, field._data);
  };
  /**
   * Note: this is hidden instead of internal as it is used to determine the type of the public
   * models.aggregators.
   *
   * If its internal, typescript won't know about it since the types will be stripped, and typescript
   * blocks will error due to incomplete type definitions.
   *
   * TODO: this should be made less brittle.
   *
   * @hidden
   */

  function createAggregators() {
    var _sdk4 = sdk,
      airtableInterface = _sdk4.__airtableInterface;
    var aggregators = {};
    var aggregatorKeys = airtableInterface.aggregators.getAllAvailableAggregatorKeys();
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;
    try {
      for (var _iterator = aggregatorKeys[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var _key = _step.value;
        var config = airtableInterface.aggregators.getAggregatorConfig(_key);
        aggregators[_key] = Object.freeze({
          key: _key,
          displayName: config.displayName,
          shortDisplayName: config.shortDisplayName,
          aggregate: aggregate.bind(null, _key),
          aggregateToString: aggregateToString.bind(null, _key)
        });
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return != null) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }
    Object.freeze(aggregators);
    return aggregators;
  }
  var sdk; // The application-level Sdk instance must be injected dynamically to avoid
  // circular dependencies at the time of module resolution.

  function __injectSdkIntoCreateAggregators(_sdk) {
    sdk = _sdk;
  }
  return exports;
}