var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.modes = exports.serialize = exports.ModeTypes = void 0;

  /** @module @airtable/blocks/models: Record Coloring */

  /** */
  // TODO(emma): update this to be an enum

  /**
   * An enum of the different types of {@link recordColoring.modes}
   *
   * @hidden
   * @alias recordColoring.ModeTypes
   */
  var ModeTypes = {
    /**
     * @alias recordColoring.ModeTypes.NONE
     */
    NONE: "none",
    /**
     * @alias recordColoring.ModeTypes.BY_SELECT_FIELD
     */
    BY_SELECT_FIELD: "bySelectField",
    /**
     * @alias recordColoring.ModeTypes.BY_VIEW
     */
    BY_VIEW: "byView"
  };
  /**
   * @hidden
   */

  exports.ModeTypes = ModeTypes;
  var serialize = mode => {
    var result = mode.type;
    if (mode.type === ModeTypes.BY_SELECT_FIELD) {
      result += "-".concat(mode.selectField.id);
    } else if (mode.type === ModeTypes.BY_VIEW) {
      result += "-".concat(mode.view.id);
    }
    return result;
  };
  /**
   * Record coloring config creators.
   *
   * @alias recordColoring.modes
   * @example
   * ```js
   * import {recordColoring} from '@airtable/blocks/models';
   * import {useRecords} from '@airtable/blocks/ui';
   *
   * // no record coloring:
   * const recordColorMode = recordColoring.modes.none();
   * // color by select field:
   * const recordColorMode = recordColoring.modes.bySelectField(someSelectField);
   * // color from view:
   * const recordColorMode = recordColoring.modes.byView(someView);
   *
   * // with useRecords:
   * const queryResult = useRecords(table, { recordColorMode })
   *
   * // with a query result:
   * const queryResult = table.selectRecords({ recordColorMode });
   * ```
   */

  exports.serialize = serialize;
  var modes = {
    /**
     * Returns a {@link RecordColorMode} that represents no record coloring.
     *
     * @alias recordColoring.modes.none
     */
    none() {
      return {
        type: ModeTypes.NONE
      };
    },
    /**
     * Returns a {@link RecordColorMode} that colors records by the specified Select field.
     *
     * @alias recordColoring.modes.bySelectField
     * @param selectField
     */
    bySelectField(selectField) {
      return {
        type: ModeTypes.BY_SELECT_FIELD,
        selectField
      };
    },
    /**
     * Returns a {@link RecordColorMode} that colors records by the specified View.
     *
     * @alias recordColoring.modes.byView
     * @param view
     */
    byView(view) {
      return {
        type: ModeTypes.BY_VIEW,
        view
      };
    }
  };
  exports.modes = modes;
  return exports;
}